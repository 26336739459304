/* eslint-disable react-hooks/exhaustive-deps */
import { CustomConnectButton } from "./components/customWalletConnectButton";
import logo from "./assets/photo.jpeg";
import { useAccount } from "wagmi";
import { useEffect, useState } from "react";
import axios from "axios";

const App = () => {
  const { address, isConnected } = useAccount();
  const [groupLink, setGroupLink] = useState("");
  const [isInsufficient, setIsInsufficient] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const telBotAPI = () => {
    const pathName = window.location.href.split("?");

    const body = JSON.stringify({
      pathName: pathName[1],
      walletAddress: [address],
    });

    const config = {
      method: "post",
      url: "https://api.gemfriend.com/api/wallet-connected",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };

    axios(config)
      .then((response) => {
        console.log("file: App.tsx:29 / .then / response:", response);

        setGroupLink(response?.data?.data?.chatlink?.invite_link);
        setIsSuccess(true);
      })
      .catch((e: any) => {
        console.log("file: App.tsx:35 / telBotAPI / e:", e);

        console.log(e.message);
        setGroupLink(e?.response?.data?.data?.chatlink?.invite_link);

        if (
          e?.response?.data?.message === "You don't have sufficient balance"
        ) {
          setIsInsufficient(true);
        }
      });
  };

  useEffect(() => {
    console.log(
      "file: App.tsx:47 / App / isConnected, address:",
      isConnected,
      address
    );
    if (isConnected && address) {
      telBotAPI();
    }
  }, [isConnected]);

  return (
    <div className="App">
      <div className="flex flex-col items-center justify-center w-full gap-10 text-center">
        <h3 className="text-4xl font-bold">Welcome to Gemfriend!</h3>
        <img src={logo} alt="" className="rounded-full h-[200px] w-[200px]" />
      </div>
      <div className="flex flex-col items-center text-center">
        {isConnected && address ? (
          <>
            <CustomConnectButton />
            {isSuccess ? (
              <p className="mb-4 text-black font-medium text-lg text-green-500">
                Successfully Connected the Wallet
              </p>
            ) : null}
            {isInsufficient ? (
              <p className="mb-4 text-black font-medium text-lg text-red-500">
                You don't have sufficient balance
              </p>
            ) : null}
            <button
              className="bg-blue-700 text-white py-4 px-10 rounded"
              onClick={() => {
                window.location.href = groupLink;
              }}
            >
              Return to Telegram
            </button>
          </>
        ) : (
          <>
            <p className="mt-10 mb-4 text-black font-medium text-xl">
              Please Connect Your Wallet for Gemfriend Bot
            </p>
            <CustomConnectButton />
          </>
        )}
      </div>
    </div>
  );
};

export default App;
